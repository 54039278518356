<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
// ************* import UTILS & HELPERS ************* //
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

// ************* STORES ************* //
import { useI18n } from 'vue-i18n'
import { FormKitSchema } from '@formkit/vue'
import useUserStore from '#/stores/userStore'
import { useAppStore } from '#/stores/appStore'
import BaseButton from '#/domains/base/components/BaseButton/BaseButton.vue'
import BaseHeading from '#/domains/base/components/BaseText/BaseHeading.vue'

const route = useRoute()

const allowResendConfirmation = ref(false)
// ************* STORES ************* //
const { login, resendConfirmationEmail } = useUserStore()
const { state: appState, updateState: updateAppState } = useAppStore()

// ************* COMPOSABLES ************* //
const { call, isFetching } = useApi()
const { displaySuccess } = useToast()
const { t } = useI18n()

const data = reactive({
  email: '',
  password: '',
})

const library = markRaw({
  BaseButton: BaseButton,
})

const formRef = ref(null)

// ************* FUNCTIONS | METHODS ************* //
const handleLogin = async (values) => {
  const res = await call(async () => await login(values), {
    successMsg: t('auth.success.login'),
    showApiError: true,
  })
  console.log(res)
  if (
    !res.success
    && res.messages.includes('Please confirm your email first')
  ) {
    allowResendConfirmation.value = true
  }
}

const formSchema = [
  {
    $formkit: 'form',
    classes: {
      form: 'w-[20rem]',
    },
    actions: false,
    onSubmit: handleLogin,
    children: [
      {
        $formkit: 'text',
        name: 'email',
        label: 'Email',
        validation: 'required|email',
      },
      {
        $formkit: 'password',
        name: 'password',
        label: 'Password',
        validation: 'required|length:5,16',
      },
      {
        $cmp: 'BaseButton',
        props: {
          type: 'submit',
        },
        children: ['Login'],
      },
    ],
  },
]

const handleResendConfirmation = async () => {
  await call(
    async () => await resendConfirmationEmail({ email: values.email }),
    {
      showApiSuccess: true,
      showApiError: true,
    },
  )
  allowResendConfirmation.value = false
}

// ************* LIFE CYCLE HOOKS ************* //
onMounted(() => {
  nextTick(() => {
    if (appState?.messages.showConfirmedSignup || route.hash === '#confirmed') {
      displaySuccess(t('auth.success.confirmedRegistration') + '🎉')
      updateAppState('messages.showConfirmedSignup', false)
    }
  })
})
</script>

<template>
  <BaseCard class="flex flex-col items-center justify-center w-max p-4 mx-auto">
    
<FormKitLazyProvider config-file="true">
<template #header>
      <BaseHeading class="text-2xl">
        Login
      </BaseHeading>
    </template>

    <FormKitSchema
      ref="formRef"
      :schema="formSchema"
      :library="library"
      :data="data"
    />

    <transition name="fade">
      <BaseButton
        v-if="allowResendConfirmation"
        class="mx-auto mb-4"
        type="submit"
        :is-loading="isFetching"
        @click="handleResendConfirmation"
      />
    </transition>

    <div class="flex flex-col items-center justify-center gap-4">
      <NuxtLink
        class="dark:text-white text-neutral-900 text-base"
        :to="{ name: 'auth-index-signup' }"
      >{{ $t("Signup") }}
      </NuxtLink>
      <NuxtLink
        class="dark:text-white text-neutral-900 text-base"
        :to="{ name: 'auth-index-resetPassword' }"
      >{{ $t("ForgotPassword") }}
      </NuxtLink>
    </div>
</FormKitLazyProvider>

  </BaseCard>
</template>
